import React from 'react'

const CustomButtonOval = ({textLoad, loading, onClick, className, children, isDisabled, ...props }) => {

    const buttonClassName = `flex bg-mts-blue-primary hover:bg-mts-blue-hover justify-center items-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 mb-2 text-white  ${isDisabled ? 'opacity-50' : ''}  ${className}`;

    return (
        <>
            {loading ?
                <button
                    onClick={onClick}
                    className={'flex justify-center items-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 mb-2'}
                    disabled={true}
                    {...props}
                >
                    <svg className="animate-spin h-5 w-5  mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span id="buttonText">{textLoad}...</span>
                </button>
                :
                <button
                    onClick={onClick}
                    className={buttonClassName}
                    disabled={isDisabled}
                    {...props}
                >
                    {children}
                </button>
            }
        </>
    )
}

export default CustomButtonOval;
