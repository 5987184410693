import React, { useEffect, useState } from "react";
import { usersResource } from "../../helpers/configRoutes/backend_helper";
import { Link, useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/modal/ConfirmDeleteModal";
import ConfirmDeleteModal from "../../components/modal/ConfirmDeleteModal";

const Team = () => {
    const nevigate = useNavigate();
    const [modalConfirm, setModalConfirm] = useState({ show: false, title: 'Delete user', loadig: true, textLoad: 'Processing' })
    const [filters, setFilters] = useState({ perPage: 5, page: 1 });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [users, setUsers] = useState([]);
    useEffect(() => {
        const getUsers = async () => {
            const response = await usersResource('get', { params: filters });
            if (response.status) {
                setUsers(response.users)
            }
        }
        getUsers()
    }, []);

    const deleteUser = async() => {
        setModalConfirm({ ...modalConfirm, loading: true })
        const response = await usersResource('del', {},modalConfirm.id);
        if (response.status) {
            setUsers(response.users)
            let newArray = users.filter((u)=>u.id !=modalConfirm.id)
            setUsers(newArray);
        }
        setModalConfirm({ ...modalConfirm, loading: false,show:false,id:null })
    }

    return (
        <>
            <div className="p-8 bg-gray-100 min-h-screen">
                {/* Header */}
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold">Team</h1>
                    <Link to={'/team-upadd'} className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                        + Add New Member
                    </Link>
                </div>

                {/* Team Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">

                    {/* Repeat similar team cards for each member */}
                    {users.map((u, i) => (
                        <div className="bg-white rounded-xl shadow-md p-6 relative" key={i}>
                            <span className={`absolute top-4 left-4 w-4 h-4 ${u.active ? 'bg-mts-green' : 'bg-mts-red'} rounded-full`}></span>
                            <img
                                src={u.imageProfile || "./images/usrAnonimo.png"}
                                alt="Portrait of Jukkoe Sisao"
                                className="w-24 h-24 rounded-full mx-auto"
                            />
                            <div className="text-center mt-4">
                                <h2 className="font-semibold text-lg mb-2">{u.first_name} {u.last_name}</h2>
                                <p className="text-gray-500 mb-2">{u.rol}</p>
                                <p className="text-gray-500 mb-2">{u.email}</p>
                            </div>
                            <div className="flex space-x-2 bg-mts-gray-button rounded-lg shadow-sm justify-center w-[33%] mx-auto">
                                {/* Botón de editar */}
                                <button onClick={() => nevigate(`/team-upadd/${u.id}`)} className={`flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100 `}>
                                    <i className="fas fa-pen text-gray-500"></i>
                                </button>
                                {/* Botón de eliminar */}
                                <button onClick={() => setModalConfirm({ ...modalConfirm, show: true, id: u.id })} className={`flex items-center justify-center px-4 py-2  ${!u.can?.delete ? '' : 'hover:bg-gray-100'}`} disabled={!u.can?.delete} >
                                    <i className={`fas fa-trash ${!u.can?.delete ? 'opacity-50' : 'text-red-500'}`}></i>
                                </button>
                            </div>
                        </div>
                    ))}
                    {/* More members... */}
                </div>
            </div>
            <ConfirmDeleteModal modal={modalConfirm} setModal={setModalConfirm} action={deleteUser} />
        </>
    )
}

export default Team;
