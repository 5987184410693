import React, { useEffect, useState } from "react";
import { plansActivate, plansResource } from "../../helpers/configRoutes/backend_helper";
import ConfirmDeleteModal from "../../components/modal/ConfirmDeleteModal";
import CustomButtonOval from "../../components/form/CustomButtonOval";
import AddUpdPlansModal from "./AddUpdPlansModal";
import FiltersPagination from "../../components/elements/FiltersPagination";
import { currencyUSD } from "../../helpers/forms/formater";

const Suscription = () => {
    const [modalConfirm, setModalConfirm] = useState({ show: false, title: 'Delete Subscriptions', loadig: false, textLoad: 'Processing', user:true})
    const [filters, setFilters] = useState({ perPage: 3, page: 1, search: '' });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [plans, setPlans] = useState([]);
    const [plan, setPlan] = useState({id:null});

    useEffect(() => {
        const getPlans = async () => {
            const response = await plansResource('get', { params: filters });
            setPlans(response.plans);
            setMeta(response.meta);
        };
        getPlans();
    }, [filters]);

    const [modal, setModal] = useState({ show: false });

    const deletePlan = async () => {
        setModalConfirm({ ...modalConfirm, loading: true })
        const response = await plansResource('del', {}, modalConfirm.id);
        if (response.status) {
            setFilters({...filters})
        }
        setModalConfirm({ ...modalConfirm, loading: false, show: false, id: null })
    }

    const openModal = (plan = {}) => {
        setPlan(plan)
        setModal({ ...modal, show: true, title: plan.id ? "Edit Subscriptions" : 'Add Subscriptions' })
    };

    const activate = async(item) => {
        const response = await plansActivate(item);
        if(response.status){
            setFilters({...filters});
        }
    }

    return (
        <>
            <div className="p-8 bg-gray-100 min-h-screen">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-[32px] font-bold">Subscriptions</h2>
                    <div className="ml-auto">
                        <CustomButtonOval onClick={() => openModal({ id: null })}>
                            + Add New Plan
                        </CustomButtonOval>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {plans.map((plan) => (
                        <div key={plan.id} className="bg-white rounded-xl shadow-lg p-6 text-center">
                            <div className="flex items-center space-x-2 mt-4 justify-end">
                                {/* Botón de edición */}
                                <button onClick={() => openModal(plan)} className="flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100">
                                    <i className="fas fa-pen text-gray-500"></i>
                                </button>
                                {/* Botón de eliminar */}
                                <button onClick={() => setModalConfirm({ ...modalConfirm, show: true, id: plan.id })} className={`flex items-center justify-center px-4 py-2 ${!plan.can?.delete ? '' : 'hover:bg-gray-100'}`} disabled={!plan.can?.delete}>
                                    <i className={`fas fa-trash ${!plan.can?.delete ? 'opacity-50' : 'text-red-500'}`}></i>
                                </button>
                            </div>
                            <h3 className="font-bold text-lg">{plan.name}</h3>
                            <p className="text-[46px] text-[#4880FF] font-bold">
                                {plan.price}
                            </p>
                            <hr className="border-t-2 border-gray-300 my-4" />
                            <div className="mt-[94px] space-y-4 text-xlg">
                                <p>${plan.price} USD</p>
                                <p>{currencyUSD(parseFloat(plan.price) == 0 ? 0:((plan.price)*(plan.taxes/100)))} TAXES</p>
                                <p>{plan.trucks} TRUCKS</p>
                                <p>{plan.users} USERS</p>
                            </div>
                            <hr className="border-t-2 border-gray-300 mt-[147px] my-4" />
                            <div className="flex justify-center mt-[40px]">
                                <CustomButtonOval onClick={()=>activate(plan)} isDisabled={!plan?.can?.activate} className={plan.active  ? 'bg-blue-500 text-white' : 'bg-transparent border border-mts-blue-primary text-mts-blue-hover '}>
                                    ACTIVE
                                </CustomButtonOval>
                            </div>
                            <p className="text-base  underline">{plan.day_trial} Day Trial</p>
                        </div>
                    ))}
                </div>
                <FiltersPagination filters={filters} setFilters={setFilters} showFilters={false} meta={meta}/>
            </div>
            <AddUpdPlansModal planItem={plan} modal={modal} setModal={setModal} filters={filters} setFilters={setFilters}/>
            <ConfirmDeleteModal modal={modalConfirm} setModal={setModalConfirm} action={deletePlan} />
        </>
    )
}

export default Suscription;