import React, { useEffect, useState } from "react";
import FiltersPagination from "../../../components/elements/FiltersPagination";
import DataTableElement from "../../../components/elements/DataTableElement";
import Columns from "./Columns";
import { paymentsResource } from "../../../helpers/configRoutes/backend_helper";

const Accounting = () => {
    const [filters, setFilters] = useState({ endDate: '', startDate: '', search: '',from:0,to:0,page:1 });
    const [tableLoad, setTableLoad] = useState({show:false,textLoad:'Loading accounts...'});
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [data, setData] = useState([]);

    useEffect(() => {
        const getPayments = async () => {
            setTableLoad({ ...tableLoad, show: true })
            const response = await paymentsResource('get', { params: filters });
            setData(response.payments);
            setMeta(response.meta);
            setTableLoad({ ...tableLoad, show: false })
        };
        getPayments();
    }, [filters]);

    const cols = Columns()

    return (
        <div className="p-8">
            <div className="text-2xl font-semibold mb-4">Accounting</div>
            <FiltersPagination showFilters={true} filters={filters} setFilters={setFilters} meta={meta} statusModel={'Payment'}>
                <div className="bg-white shadow-md rounded-lg">
                    <DataTableElement columns={cols} items={data} tableLoad={tableLoad} />
                </div>
            </FiltersPagination>
        </div>
    );
};

export default Accounting;
