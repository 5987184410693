import axios from 'axios'

export const axiosClient = axios.create({
    //baseURL: process.env.REACT_APP_BACKEND_URL + '/api/cms',
    baseURL:'http://localhost:8000'+ '/api',
    headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json; charset=utf-8'
    },
});

export const axiosCookie = axios.create({
    //baseURL: process.env.REACT_APP_BACKEND_URL,
    baseURL:'http://localhost:8000',
    headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json; charset=utf-8'
    }
});