import { UPDATE_LOADER } from "./actionTypes";

const initialState = {
    loader: false,
};

const loader = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LOADER:
            // Actualizar el estado
            const newState = {
                ...state,
                loader: action.payload
            };
            return newState;
        default:
            return state;
    }
};

export default loader;
