import { UPDATE_USUARIO, RESET_USUARIO, ADD_ERRORS } from "./actionTypes";

// Función para cargar el estado desde localStorage
const loadState = () => {
    try {
        const serializedState = localStorage.getItem('usuarioRegistroState');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

// Función para guardar el estado en localStorage
const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('usuarioRegistroState', serializedState);
    } catch {
        // Manejar errores de almacenamiento
    }
};

const initialState = loadState() || {
    error: {},
    usuario: {
        perfil_id: "",
        nombre: "",
        apellidos: "",
        fechaNacimiento: "",
        movil: "",
        telefono: "",
        email: "",
        contrasena: "",
        confirmacionContrasena: "",
        curp: "",
        estadoCivil: "",
        archivo: "",
        calle: "",
        cp: "",
        numeroExterior: "",
        numeroInterior: "",
        pais: null,
        estado: null,
        colonia: "",
        municipio: null,
        descripcionPersonal: "",
        informacionUnica: "",
        interesesPersonales: [],
        contrato: false,
        codigo: false,
        privacidad: false,
        perfilImg: '',
        paciente: {
            nombre: "",
            apellidos: "",
            telefono: "",
            curp: "",
            email: "",
            estadoCivil: "",
            edad: '',
            //Direccion Paciente
            calle: "",
            cp: "",
            numeroExterior: "",
            numeroInterior: "",
            pais: "",
            estado: "",
            colonia: "",
            municipio: "",
            descripcionPersonal: "",
            informacionUnica: "",
            interesesPersonales: [],
            patologia: false,
            diagnostico: '',
            condicionesEspeciales: '',
            medico_nombre: '',
            medico_cedula: '',
            medico_telefono: '',
            medico_movil: '',
            especialista_id: null,
            cuidados: [],
            alergias: '',
            informacionAdicional: '',
            recurrente: false,
            dias: [],
            fecha_inicio: '',
            fecha_fin: '',
            turno_id: '',
            tiempo_id: null,
            perfilImg: ''
        },
        bancarios: {
            tarjeta_nombre: '',
            tarjeta: '',
            vencimiento: '',
            cvv: '',
            cvvConfirm: '',
            entidad_id: '',
            entidad_financiera: '',
            cuenta_nombre: '',
            clabe: '',
            cuenta_no: '',
            is_factura: false,
            dir_admin: false,
            nombre: '',
            rfc: '',
            email: '',
            telefono: ''
        },
        acreditaciones: {
            estudios: '',
            casa_estudios: '',
            cedula_profecional: '',
            graduacion_fecha: '',
            descripcion_profecional: '',
            experiencias_laborales: [],
            formacion_academica: [],
            formacion_especialidades: [],
            habilidades: [],
            servicios: []
        },
        contratos: {
            prestacion: false,
            codigo: false,
            privacidad: false
        }
    },
};

const usuario = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USUARIO:
            // Actualizar el estado
            const newState = {
                ...state,
                usuario: action.payload
            };
            // Guardar el estado en localStorage
            saveState(newState);
            return newState;
        case RESET_USUARIO:
            localStorage.removeItem("usuarioRegistroState");
            return initialState;
        // Guardar el estado en localStorage
        //saveState(resetState);
        case ADD_ERRORS:
            const errorsState = {
                ...state,
                errors: action.payload
            };
            // Guardar el estado en localStorage
            saveState(errorsState);
            return errorsState;
        default:
            return state;
    }
};

export default usuario;
